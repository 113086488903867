import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { IoLanguageOutline } from "react-icons/io5"
import { theme } from "../theme"
import { FixedRatio } from "./basic/fixed-ratio"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0.5rem 1rem;
  background: ${theme.colors.backgroundSecondary};

  h3 {
    margin: 0;
  }

  section {
    flex: 1;
    display: flex;

    a {
      flex: 1;
      overflow: hidden;
      position: relative;
      border: 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 5rem;

        @media (min-width: 440px) {
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            ${theme.colors.backgroundSecondary}
          );
        }
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: calc(0.5rem + 1px);
    border-top: 1px solid ${theme.colors.highlightDark};

    ul {
      display: flex;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;

      li {
        font-size: ${theme.fontSizes[0]};
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 100%;
        border: 0;
        color: ${theme.colors.textLight};
        background: ${theme.colors.highlight};
        text-transform: uppercase;
        font-weight: ${theme.fontWeights.bold};

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
`

const PublishedDate = styled.div`
  padding: 0.45rem 0;
  font-size: ${theme.fontSizes[0]};
`

const LanguageIcon = styled(IoLanguageOutline)`
  font-size: 1.5rem;
`

export const Interview = ({
  title,
  description,
  slug,
  date,
  isDraft,
  languages = [],
}) => {
  return (
    <FixedRatio ratio={[1, 1]}>
      <Container>
        <section>
          <Link to={`/${slug}`}>
            <header>
              <h3>
                <span className="link">{title}</span>
              </h3>
              {isDraft && <strong>Draft </strong>}
            </header>

            <p
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Link>
        </section>
        <footer>
          <PublishedDate>{date}</PublishedDate>
          {languages.length > 0 && (
            <ul>
              <li>
                <LanguageIcon />
              </li>
              {languages.map(({ slug, lang }) => (
                <li key={lang}>
                  <Link to={`/${slug}`}>{lang}</Link>
                </li>
              ))}
            </ul>
          )}
        </footer>
      </Container>
    </FixedRatio>
  )
}
