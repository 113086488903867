import styled from "@emotion/styled"
import React from "react"

const Container = styled.div`
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;

    @media (min-width: 440px) {
      padding-top: ${({ ratio }) => `calc(${ratio} * 100%)`};
    }
  }
`

const Content = styled.div`
  display: flex;

  > * {
    flex: 1;
  }

  @media (min-width: 440px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const FixedRatio = ({ ratio: [x, y], children }) => {
  return (
    <Container ratio={y / x}>
      <Content>{children}</Content>
    </Container>
  )
}
