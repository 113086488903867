import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { Background } from "../components/backgrounds/interviews"
import { Interview } from "../components/interview"
import { Layout, Row } from "../components/layout"
import { SEO } from "../components/seo"
import { theme } from "../theme"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-auto-rows: 1fr;
  column-gap: 0.5rem;
  row-gap: 2rem;
`

const Header = styled.header`
  h1 {
    color: ${theme.colors.secondary};
  }
`

const Interviews = ({ data }) => {
  const { posts, languages } = data.allMdx

  const languagesByLangDir = languages.reduce(
    (acc, { nodes, fieldValue }) => ({
      ...acc,
      [fieldValue]: nodes.map(({ fields }) => fields),
    }),
    {}
  )

  const interviews = posts.filter(({ fields }) => fields.lang === "en")

  return (
    <Background>
      <Layout>
        <SEO title="Interviews" />
        <div>
          <Row>
            <Header>
              <h1>Interviews</h1>
            </Header>

            <Grid>
              {interviews.map(({ frontmatter, fields, excerpt }) => {
                const { slug, draft, lang, langParentDir } = fields
                const title = frontmatter.title || slug
                const description = frontmatter.description || excerpt
                const variants = languagesByLangDir[langParentDir] || []
                const otherLangVariants = variants.filter(v => v.lang !== lang)

                return (
                  <Interview
                    key={slug}
                    title={title}
                    description={description}
                    slug={slug}
                    date={frontmatter.date}
                    isDraft={draft}
                    languages={otherLangVariants}
                  />
                )
              })}
            </Grid>
          </Row>
        </div>
      </Layout>
    </Background>
  )
}

export default Interviews

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { fields: { visible: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: nodes {
        excerpt
        fields {
          slug
          draft
          lang
          langParentDir
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
      languages: group(field: fields___langParentDir) {
        nodes {
          fields {
            lang
            slug
          }
        }
        fieldValue
      }
    }
  }
`
