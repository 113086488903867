import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled"
import { theme } from "../../theme"
import { fullscreen } from "../../styles/global"

const FullBackground = ({ className, children }) => {
  const { mobile, desktop } = useStaticQuery(
    graphql`
      query {
        mobile: file(relativePath: { eq: "interviews-background.jpg" }) {
          image: childImageSharp {
            fluid(quality: 100, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktop: file(relativePath: { eq: "interviews-background.jpg" }) {
          image: childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const images = [
    mobile.image.fluid,
    {
      ...desktop.image.fluid,
      media: "(min-width: 500px)",
    },
  ]

  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={images}
      backgroundColor={theme.colors.backgroundSecondary}
      role="img"
      aria-label="Fullscreen Background"
      preserveStackingContext={true}
    >
      {children}
    </BackgroundImage>
  )
}

export const Background = styled(FullBackground)`
  ${fullscreen};
  background-attachment: fixed;
`
